import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import "./portfolio.scss"

const SectionProjects = ({}) => {
  const { allContentfulProject } = useStaticQuery(graphql`
    query {
      allContentfulProject(sort: { order: DESC, fields: publishDate }) {
        edges {
          node {
            title
            slug
            subtitle
            tags
            projectThumbnail {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
  `)
  const projects = allContentfulProject.edges
  return (
    <section className="projects">
      <div className="wrapper">
        <div className="title animated fadeInDown">
          <h2>Portfolio</h2>
          <h3 className="subtitle">Sample of some of my recent work and projects. <a href="mailto:juwiling@gmail.com">Contact me</a> if you'd like me to present aspects of propietary work that I'm at the moment not permitted to share publicly.<br></br><br></br><br></br><br></br></h3>
        </div>
        <div className="row">
          {projects.map(({ node }) => {
            return (
              <div className="col-md-6">
                <Link className="project animated fadeInDown" to={node.slug}>
                  {node.projectThumbnail && <img src={node.projectThumbnail.file.url} alt={node.projectThumbnail.file.title}/>}
                  <h4 className="icon ion-ios-arrow-forward">
                  <span>{node.title}</span>
                  <small>{node.subtitle}</small></h4>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

const Portfolio = () => (
  <Layout mainClassName="portfolio">
    <SEO title="Portfolio" description="Selection of projects that I've worked on over the years."/>
    <SectionProjects />
  </Layout>
)

export default Portfolio
